.dropdown {
  position: absolute;
  z-index: var(--z-index-click-dropdown);
  min-width: 325px;
  background-color: white;
  box-shadow: 0 2px 13px 0 #0003;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.label {
  display: flex;
}

.delimiter {
  font-size: 14px;
  font-weight: 600;
}

.container {
  padding: 10px 14px;
  font-size: 12px;
  font-weight: 600;
  background: var(--color-gray00);
  color: var(--color-gray90);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-right: 6px;
  border-radius: 16px;
  border: 1px solid var(--color-gray40);
  display: flex;
  height: 32px;
}

.container:hover {
  background: var(--color-gray05);
  border-color: var(--color-gray90);
}

.value {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.container svg {
  margin-left: 6px;
  position: relative;
  top: 1px;
}

.dropdownRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #e3e1eb;
  padding: 16px 0;
}

.dropdownRow:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
  justify-content: flex-end;
}

.dropdownRow:first-of-type {
  padding-top: 0;
}

.buttonWhite {
  background-color: white;
  color: black;
}

.done {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: var(--color-brandShadeDark);
  height: 32px;
  color: var(--color-gray00);
  border: 1px solid var(--color-brandShadeDark);
  padding: 0 20px;
  cursor: pointer;
  white-space: nowrap;
}

.datePickerDropdown {
  padding: 0;
  border-radius: 8px;
}

.datePickerHeader {
  background-color: #ebe6fe;
  border-radius: 8px 8px 0 0;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e3e1eb;
}

.datePickerBody {
  padding: 24px 16px 16px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: row;
  gap: 16px;
  width: 100%;
}

/* .monthRow {
  flex-direction: row;
  border-radius: 10px;
 padding: 8px 0;
} */
.monthButton {
  width: 100%;
  text-align: center;
}

.monthButton button {
  border-radius: 10px;
  font-weight: 600;
}

.active {
  background: var(--color-gray05);
  border-color: var(--color-gray90);
}

.hidden button {
  display: none;
}

.inRange button {
  background-color: var(--color-gray05);
  color: black;
}

.inactive button {
  background-color: white;
  color: black;
}

.yearButton {
  background-color: transparent;
}

.datePickerHeader select {
  background-color: transparent;
  color: black;
}

.datePickerFooter {
  padding: 16px;
  justify-content: space-between;
  font-weight: 600;
}
