.DropdownList {
  padding: 5px 0;
  min-height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.DropdownHeader {
  cursor: default;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-gray10);
  height: 56px;
  padding: 16px;
}

.DropdownFooter {
  cursor: default;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--color-gray10);
  height: 56px;
  padding: 16px;
}
