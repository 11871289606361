:root {
  /* Font sizes */
  --font-size-page-heading: 32px;
  --font-size-heading: 20px;
  --font-size-subheading: 16px;
  --font-size-body: 14px;
  --font-size-caption: 12px;
  --font-size-fieldlabel: 14px;

  /* Font faces */
  --font-family-primary: 'Inter';
  --font-family-secondary: 'Gilroy';

  /* Colors */
  --color-gray00: white;
  --color-gray02: #fbfaff;
  --color-gray05: #f1f0f7;
  --color-gray10: #e3e1eb;
  --color-gray20: #cac9d1;
  --color-gray30: #b2b1ba;
  --color-gray40: #9a98a3;
  --color-gray50: #817f8a;
  --color-gray60: #676570;
  --color-gray70: #4d4c54;
  --color-gray80: #323138;
  --color-gray90: #1b1a1f;
  --color-brandTintLight: #a27efc;
  --color-brandTintDark: #7450fa;
  --color-brandFull: #5533c9;
  --color-brandFullRGB: 85, 51, 201;
  --color-brandNewPurple60: #4a33a0;
  --color-brandOutline: rgba(var(--color-brandFullRGB), 0.2);
  --color-brandShadeLight: #301e6b;
  --color-brandShadeDark: #181547;
  --color-brandShadeDarkRGB: 24, 21, 71;
  --color-brandSolar: #f74857;
  --color-blueTintDark: #91d5ff;
  --color-greenTintLight: #f6ffed;
  --color-greenTintDark: #a6e47a;
  --color-greenTintDarkAlt: #b7eb8f;
  --color-greenFull: #52c41a;
  --color-greenShadeLight: #237804;
  --color-greenShadeDark: #092b00;
  --color-redTintLight: #fff1f0;
  --color-redTintDark: #ff8e8a;
  --color-redFull: #dd1d1d;
  --color-redFullAlt: #cf4937;
  --color-redShadeLight: #a8071a;
  --color-redShadeDark: #5c0011;
  --color-orangeTintLight: #fff7e6;
  --color-orangeTintDark: #ffcb7d;
  --color-orangeFull: #fa8c16;
  --color-orangeShadeLight: #ad4e00;
  --color-orangeShadeDark: #612500;
  --color-yellowTintLight: #feffe6;
  --color-yellowTintDark: #fff87b;
  --color-yellowFull: #fadb14;
  --color-yellowShadeLight: #ad8b00;
  --color-yellowShadeDark: #614700;
  --color-tealTintLight: #e6fffb;
  --color-tealTintDark: #87e8de;
  --color-tealFull: #13c2c2;
  --color-tealShadeLight: #006d75;
  --color-tealShadeDark: #002329;
  --color-blueTintLight: #e6f7ff;
  --color-blueTintDark: #7dcbff;
  --color-blueFull: #1890ff;
  --color-blueShadeLight: #0050b3;
  --color-blueShadeDark: #002766;
  --box-shadow-default: 0 21.53px 25.84px rgba(0, 0, 0, 0.06), 0 8.70566px 23.2151px rgba(0, 0, 0, 0.08), 0 2.90189px 2.90189px rgba(0, 0, 0, 0.08);
}

/* .Text comes first so later styles are
 * applied without managing specificity */
.Text {
  color: var(--color-gray90);
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: normal;
  display: inline;
  line-height: 150%;
}

.ColorGray00 {
  color: var(--color-gray00);
}

.ColorGray02 {
  color: var(--color-gray02);
}

.ColorGray05 {
  color: var(--color-gray05);
}

.ColorGray10 {
  color: var(--color-gray10);
}

.ColorGray20 {
  color: var(--color-gray20);
}

.ColorGray30 {
  color: var(--color-gray30);
}

.ColorGray40 {
  color: var(--color-gray40);
}

.ColorGray50 {
  color: var(--color-gray50);
}

.ColorGray60 {
  color: var(--color-gray60);
}

.ColorGray70 {
  color: var(--color-gray70);
}

.ColorGray80 {
  color: var(--color-gray80);
}

.ColorGray90 {
  color: var(--color-gray90);
}

.ColorBrandTintLight {
  color: var(--color-brandTintLight);
}

.ColorBrandTintDark {
  color: var(--color-brandTintDark);
}

.ColorBrandFull {
  color: var(--color-brandFull);
}

.ColorBrandShadeLight {
  color: var(--color-brandShadeLight);
}

.ColorBrandShadeDark {
  color: var(--color-brandShadeDark);
}

.ColorBrandSolar {
  color: var(--color-brandSolar);
}

.ColorGreenTintLight {
  color: var(--color-greenTintLight);
}

.ColorGreenTintDark {
  color: var(--color-greenTintDark);
}

.ColorGreenFull {
  color: var(--color-greenFull);
}

.ColorGreenShadeLight {
  color: var(--color-greenShadeLight);
}

.ColorGreenShadeDark {
  color: var(--color-greenShadeDark);
}

.ColorRedTintLight {
  color: var(--color-redTintLight);
}

.ColorRedTintDark {
  color: var(--color-redTintDark);
}

.ColorRedFull {
  color: var(--color-redFull);
}

.ColorRedShadeLight {
  color: var(--color-redShadeLight);
}

.ColorRedShadeDark {
  color: var(--color-redShadeDark);
}

.ColorOrangeTintLight {
  color: var(--color-orangeTintLight);
}

.ColorOrangeTintDark {
  color: var(--color-orangeTintDark);
}

.ColorOrangeFull {
  color: var(--color-orangeFull);
}

.ColorOrangeShadeLight {
  color: var(--color-orangeShadeLight);
}

.ColorOrangeShadeDark {
  color: var(--color-orangeShadeDark);
}

.ColorYellowTintLight {
  color: var(--color-yellowTintLight);
}

.ColorYellowTintDark {
  color: var(--color-yellowTintDark);
}

.ColorYellowFull {
  color: var(--color-yellowFull);
}

.ColorYellowShadeLight {
  color: var(--color-yellowShadeLight);
}

.ColorYellowShadeDark {
  color: var(--color-yellowShadeDark);
}

.ColorTealTintLight {
  color: var(--color-tealTintLight);
}

.ColorTealTintDark {
  color: var(--color-tealTintDark);
}

.ColorTealFull {
  color: var(--color-tealFull);
}

.ColorTealShadeLight {
  color: var(--color-tealShadeLight);
}

.ColorTealShadeDark {
  color: var(--color-tealShadeDark);
}

.ColorBlueTintLight {
  color: var(--color-blueTintLight);
}

.ColorBlueTintDark {
  color: var(--color-blueTintDark);
}

.ColorBlueFull {
  color: var(--color-blueFull);
}

.ColorBlueShadeLight {
  color: var(--color-blueShadeLight);
}

.ColorBlueShadeDark {
  color: var(--color-blueShadeDark);
}

.BackgroundGray00 {
  background-color: var(--color-gray00);
}

.BackgroundGray02 {
  background-color: var(--color-gray02);
}

.BackgroundGray05 {
  background-color: var(--color-gray05);
}

.BackgroundGray10 {
  background-color: var(--color-gray10);
}

.BackgroundGray20 {
  background-color: var(--color-gray20);
}

.BackgroundGray30 {
  background-color: var(--color-gray30);
}

.BackgroundGray40 {
  background-color: var(--color-gray40);
}

.BackgroundGray50 {
  background-color: var(--color-gray50);
}

.BackgroundGray60 {
  background-color: var(--color-gray60);
}

.BackgroundGray70 {
  background-color: var(--color-gray70);
}

.BackgroundGray80 {
  background-color: var(--color-gray80);
}

.BackgroundGray90 {
  background-color: var(--color-gray90);
}

.BackgroundBrandTintLight {
  background-color: var(--color-brandTintLight);
}

.BackgroundBrandTintDark {
  background-color: var(--color-brandTintDark);
}

.BackgroundBrandFull {
  background-color: var(--color-brandFull);
}

.BackgroundBrandOutline {
  background-color: var(--color-brandOutline);
}

.BackgroundBrandShadeLight {
  background-color: var(--color-brandShadeLight);
}

.BackgroundBrandShadeDark {
  background-color: var(--color-brandShadeDark);
}

.BackgroundBrandSolar {
  background-color: var(--color-brandSolar);
}

.BackgroundGreenTintLight {
  background-color: var(--color-greenTintLight);
}

.BackgroundGreenTintDark {
  background-color: var(--color-greenTintDark);
}

.BackgroundGreenFull {
  background-color: var(--color-greenFull);
}

.BackgroundGreenShadeLight {
  background-color: var(--color-greenShadeLight);
}

.BackgroundGreenShadeDark {
  background-color: var(--color-greenShadeDark);
}

.BackgroundRedTintLight {
  background-color: var(--color-redTintLight);
}

.BackgroundRedTintDark {
  background-color: var(--color-redTintDark);
}

.BackgroundRedFull {
  background-color: var(--color-redFull);
}

.BackgroundRedShadeLight {
  background-color: var(--color-redShadeLight);
}

.BackgroundRedShadeDark {
  background-color: var(--color-redShadeDark);
}

.BackgroundOrangeTintLight {
  background-color: var(--color-orangeTintLight);
}

.BackgroundOrangeTintDark {
  background-color: var(--color-orangeTintDark);
}

.BackgroundOrangeFull {
  background-color: var(--color-orangeFull);
}

.BackgroundOrangeShadeLight {
  background-color: var(--color-orangeShadeLight);
}

.BackgroundOrangeShadeDark {
  background-color: var(--color-orangeShadeDark);
}

.BackgroundYellowTintLight {
  background-color: var(--color-yellowTintLight);
}

.BackgroundYellowTintDark {
  background-color: var(--color-yellowTintDark);
}

.BackgroundYellowFull {
  background-color: var(--color-yellowFull);
}

.BackgroundYellowShadeLight {
  background-color: var(--color-yellowShadeLight);
}

.BackgroundYellowShadeDark {
  background-color: var(--color-yellowShadeDark);
}

.BackgroundTealTintLight {
  background-color: var(--color-tealTintLight);
}

.BackgroundTealTintDark {
  background-color: var(--color-tealTintDark);
}

.BackgroundTealFull {
  background-color: var(--color-tealFull);
}

.BackgroundTealShadeLight {
  background-color: var(--color-tealShadeLight);
}

.BackgroundTealShadeDark {
  background-color: var(--color-tealShadeDark);
}

.BackgroundBlueTintLight {
  background-color: var(--color-blueTintLight);
}

.BackgroundBlueTintDark {
  background-color: var(--color-blueTintDark);
}

.BackgroundBlueFull {
  background-color: var(--color-blueFull);
}

.BackgroundBlueShadeLight {
  background-color: var(--color-blueShadeLight);
}

.BackgroundBlueShadeDark {
  background-color: var(--color-blueShadeDark);
}

.BackgroundTransparent {
  background-color: transparent;
}

.BorderGray00 {
  border-color: var(--color-gray00);
}

.BorderGray02 {
  border-color: var(--color-gray02);
}

.BorderGray05 {
  border-color: var(--color-gray05);
}

.BorderGray10 {
  border-color: var(--color-gray10);
}

.BorderGray20 {
  border-color: var(--color-gray20);
}

.BorderGray30 {
  border-color: var(--color-gray30);
}

.BorderGray40 {
  border-color: var(--color-gray40);
}

.BorderGray50 {
  border-color: var(--color-gray50);
}

.BorderGray60 {
  border-color: var(--color-gray60);
}

.BorderGray70 {
  border-color: var(--color-gray70);
}

.BorderGray80 {
  border-color: var(--color-gray80);
}

.BorderGray90 {
  border-color: var(--color-gray90);
}

.BorderBrandTintLight {
  border-color: var(--color-brandTintLight);
}

.BorderBrandTintDark {
  border-color: var(--color-brandTintDark);
}

.BorderBrandFull {
  border-color: var(--color-brandFull);
}

.BorderBrandShadeLight {
  border-color: var(--color-brandShadeLight);
}

.BorderBrandShadeDark {
  border-color: var(--color-brandShadeDark);
}

.BorderBrandSolar {
  border-color: var(--color-brandSolar);
}

.BorderBrandOutline {
  border-color: var(--color-brandOutline);
}

.BorderGreenTintLight {
  border-color: var(--color-greenTintLight);
}

.BorderGreenTintDark {
  border-color: var(--color-greenTintDark);
}

.BorderGreenFull {
  border-color: var(--color-greenFull);
}

.BorderGreenShadeLight {
  border-color: var(--color-greenShadeLight);
}

.BorderGreenShadeDark {
  border-color: var(--color-greenShadeDark);
}

.BorderRedTintLight {
  border-color: var(--color-redTintLight);
}

.BorderRedTintDark {
  border-color: var(--color-redTintDark);
}

.BorderRedFull {
  border-color: var(--color-redFull);
}

.BorderRedShadeLight {
  border-color: var(--color-redShadeLight);
}

.BorderRedShadeDark {
  border-color: var(--color-redShadeDark);
}

.BorderOrangeTintLight {
  border-color: var(--color-orangeTintLight);
}

.BorderOrangeTintDark {
  border-color: var(--color-orangeTintDark);
}

.BorderOrangeFull {
  border-color: var(--color-orangeFull);
}

.BorderOrangeShadeLight {
  border-color: var(--color-orangeShadeLight);
}

.BorderOrangeShadeDark {
  border-color: var(--color-orangeShadeDark);
}

.BorderYellowTintLight {
  border-color: var(--color-yellowTintLight);
}

.BorderYellowTintDark {
  border-color: var(--color-yellowTintDark);
}

.BorderYellowFull {
  border-color: var(--color-yellowFull);
}

.BorderYellowShadeLight {
  border-color: var(--color-yellowShadeLight);
}

.BorderYellowShadeDark {
  border-color: var(--color-yellowShadeDark);
}

.BorderTealTintLight {
  border-color: var(--color-tealTintLight);
}

.BorderTealTintDark {
  border-color: var(--color-tealTintDark);
}

.BorderTealFull {
  border-color: var(--color-tealFull);
}

.BorderTealShadeLight {
  border-color: var(--color-tealShadeLight);
}

.BorderTealShadeDark {
  border-color: var(--color-tealShadeDark);
}

.BorderBlueTintLight {
  border-color: var(--color-blueTintLight);
}

.BorderBlueTintDark {
  border-color: var(--color-blueTintDark);
}

.BorderBlueFull {
  border-color: var(--color-blueFull);
}

.BorderBlueShadeLight {
  border-color: var(--color-blueShadeLight);
}

.BorderBlueShadeDark {
  border-color: var(--color-blueShadeDark);
}

.BorderWidthNone {
  border-width: 0;
}

.BorderWidthOne {
  border-width: 1px;
}

.BorderWidthTwo {
  border-width: 2px;
}

.BorderWidthFour {
  border-width: 4px;
}

.BorderRadiusTwo {
  border-radius: 2px;
}

.BorderRadiusFour {
  border-radius: 4px;
}

.BorderRadiusEight {
  border-radius: 8px;
}

.BorderRadiusSixteen {
  border-radius: 16px;
}

.BorderStyleSolid {
  border-style: solid;
}

.BorderStyleDashed {
  border-style: dashed;
}

.Inherit {
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  color: inherit;
  text-decoration: inherit;
}

.Bold {
  font-weight: 700;
}

.Semibold {
  font-weight: 600;
}

.Italic {
  font-style: italic;
}

.Block {
  display: block;
  margin: 12px 0;

  /* expected to collapse */
}

.Underline {
  text-decoration: underline;
}

.Nobr {
  white-space: nowrap;
}

.Uppercase {
  text-transform: uppercase;
}

.PageHeading {
  composes: Text;
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-page-heading);
  max-width: 100%;
  word-break: break-all;
}

.Heading {
  composes: Text;
  font-size: var(--font-size-heading);
}

.Subheading {
  composes: Text;
  font-size: var(--font-size-subheading);
}

.Body {
  composes: Text;
  font-size: var(--font-size-body);
}

.Caption {
  composes: Text;
  font-size: var(--font-size-caption);
}

.FieldLabel {
  composes: Text Bold;
  font-size: var(--font-size-fieldlabel);
}
