@keyframes shimmer {
  to {
    background-position: -200% 0;
  }

  from {
    background-position: 200% 0;
  }
}

.shimmer {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-size: 200% 100%;
  border-radius: 10px;
  animation: shimmer 1.5s infinite;
}

.shimmerHeader {
  display: flex;
  align-content: center;
}

@keyframes shimmerEffect {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.shimmerEffect {
  background: linear-gradient(
    90deg,
    #f0f0f0 25%,
    #e0e0e0 50%,
    #f0f0f0 75%
  );
  background-size: 1000px 100%;
  animation: shimmerEffect 2s infinite linear;
}

.shimmerContainer {
  padding: 24px;
  background: white;
  width: 100%;
  max-width: 1200px;
}

.headerText {
  margin-bottom: 24px;
}

.headerShimmer {
  height: 20px;
  width: 60%;
  max-width: 320px;
  border-radius: 4px;
}

.metricsRow {
  display: grid;
  grid-template-columns: repeat(var(--columns, 3), 1fr);
  gap: 24px;
  margin-bottom: 16px;
}

.metricCard {
  background: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  height: 123px;
}

.metricCard .small {
  height: 103px;
}

.metricTitle {
  height: 24px;
  width: 40%;
  max-width: 120px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.metricsSection {
  margin-bottom: 64px;
}

.metricContent {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.metricValue {
  height: 32px;
  width: 100px;
  border-radius: 4px;
}

.metricPercent {
  height: 24px;
  width: 60px;
  border-radius: 4px;
}

.viewDetails {
  height: 20px;
  width: 80px;
  border-radius: 4px;
}

.chartsRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 24px;
}

.chartCard {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.chartHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.chartTitle {
  height: 24px;
  width: 200px;
  border-radius: 4px;
}

.chartControls {
  display: flex;
  gap: 8px;
}

.control {
  height: 32px;
  width: 80px;
  border-radius: 4px;
}

.aiLabel {
  height: 20px;
  width: 160px;
  border-radius: 4px;
}

.funnelChart,
.sentimentChart {
  height: 213px;
  border-radius: 8px;
}

.listChart {
  height: 248px;
  border-radius: 8px;
}

.funnelChart {
  width: 604px;
}
