.wrapper {
  position: relative;
  padding-right: 50px;
  display: flex;
  flex: 1 1;
  color: var(--color-gray90);
}

.templateData {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  padding-left: 25px;
}

.preview {
  height: 80px;
  width: 80px;
  border: none;
  cursor: pointer;
  background: transparent;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.description {
  composes: kai-line-clamp-1 from global;
  margin-top: 15px;
  font-size: 13px;
  font-weight: 600;
  color: var(--color-gray50);
}

.button {
  color: var(--color-gray80);
  font-size: 16px;
  text-align: center;
  border-radius: 2px;
  border: solid 1px transparent;
  background-color: transparent;
  transform: rotate(90deg);
  cursor: pointer;
  letter-spacing: 2px;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
}

.colHeader {
  font-size: 9px;
  text-transform: uppercase;
  color: var(--color-gray70);
  margin-bottom: 10px;
  font-weight: 600;
}

.colData {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
  color: var(--color-gray90);
}

.title {
  width: 320px;
  font-size: 16px;
  font-weight: 600;
}

.created {
  font-size: 12px;
  line-height: 15.6px;
  color: var(--color-gray50);
}

.createdAt {
  font-weight: 700;
  padding-right: 3px;
}

.createdBy > span {
  font-weight: 700;
}

.spinnerContainer {
  position: absolute;
  opacity: 0.5;
  composes: kai-flex-row from global;
  justify-content: space-around;
  width: 100%;
  width: 20px;
  height: 20px;
  margin: 0 7px;
}

.disabledOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: var(--color-gray05);
  left: 0;
  composes: kai-flex-row from global;
  justify-content: space-around;
  top: 0;
}

.spinnerContainer > div {
  height: 20px;
  width: 20px;
}

.spinnerContainer > div > svg:first-child {
  height: 20px;
  width: 20px;
}

.spinnerContainer > div > svg:last-child {
  height: 10px;
  width: 10px;
  top: 10px;
  left: 10px;
}

.status {
  width: 150px;
}
