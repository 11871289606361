.dateRadioGroup {
  margin-bottom: 16px;
}

.dateRadio {
  margin-right: 12px;
}

.dateTimeInput {
  width: 224px;
  margin-right: 32px;
  position: relative;
}

.dailyRunTimeContainer {
  gap: 16px;
}

.dailyRunTimeInput {
  width: 240px;
  position: relative;
}

.simpleExpression::after {
  content: '';
  clear: both;
  display: table;
}

.criteriaSelectorLeft {
  float: left;
  padding-right: 5px;
  width: 45%;
  cursor: pointer;
  position: relative;
}

.criteriaSelectorMiddle {
  float: left;
  width: 15%;
  padding-right: 5px;
}

.criteriaSelectorRight {
  width: 40%;
  float: left;
  cursor: pointer;
  position: relative;
  padding-right: 5px;
}

.criteriaSelectorLeft.selectorDisabled,
.criteriaSelectorMiddle.selectorDisabled,
.criteriaSelectorRight.selectorDisabled {
  cursor: not-allowed;
}
