.tableauVizContainer {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  position: relative;
}

.tableauVizContainer.hidden {
  opacity: 0;
}
